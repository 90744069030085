import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-agency-four',
  templateUrl: './digital-agency-four.component.html',
  styleUrls: ['./digital-agency-four.component.scss']
})
export class DigitalAgencyFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
