import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-agency',
  templateUrl: './seo-agency.component.html',
  styleUrls: ['./seo-agency.component.scss']
})
export class SeoAgencyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
