<!-- Start Main Banner Area -->
<div class="uk-corporate-banner corporate-main-banner">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item">
                <div class="corporate-banner-content">
                    <div class="content">
                        <h1>We take care of the future</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverraaccumsan lacus vel facilisis.</p>
                        <a href="#" class="uk-button uk-button-default">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="corporate-banner-image">
                    <img src="assets/img/banner-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape5.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape6.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape7.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area uk-featured-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <p>We bring the right people together to challenge established thinking and drive transformation.</p>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3>Business Planning</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>
                    <div class="bg-shape"><img src="assets/img/box-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-targeting"></i>
                    </div>
                    <h3>Performance</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>
                    <div class="bg-shape"><img src="assets/img/box-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-magnifying-glass"></i>
                    </div>
                    <h3>A Full Service</h3>
                    <div class="bar"></div>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur adipiscing elit.</p>
                    <div class="bg-shape"><img src="assets/img/box-shape.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start What We Do Area -->
<section class="what-we-do-section uk-what-we-do">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="what-we-do-image">
                    <img src="assets/img/what-we-do-image.jpg" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="what-we-do-content">
                    <div class="content">
                        <div class="uk-section-title section-title">
                            <span>What We Do</span>
                            <h2>Our Services</h2>
                            <div class="bar"></div>
                        </div>

                        <div class="single-services">
                            <div class="icon">
                                <i class="flaticon-plan"></i>
                            </div>
                            <h3>Digital Branding</h3>
                            <i class="flaticon-right link-btn"></i>
                            <a href="#" class="link uk-position-cover"></a>
                        </div>

                        <div class="single-services">
                            <div class="icon">
                                <i class="flaticon-think"></i>
                            </div>
                            <h3>Creative Solutions</h3>
                            <i class="flaticon-right link-btn"></i>
                            <a href="#" class="link uk-position-cover"></a>
                        </div>

                        <div class="single-services">
                            <div class="icon">
                                <i class="flaticon-shout"></i>
                            </div>
                            <h3>Marketing Solutions</h3>
                            <i class="flaticon-right link-btn"></i>
                            <a href="#" class="link uk-position-cover"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End What We Do Area -->

<!-- Start Experience Area -->
<section class="experience-area uk-experience">
    <div class="uk-container-expand">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="experience-content">
                    <div class="content">
                        <div class="uk-section-title section-title">
                            <span>Growing With Our Clients</span>
                            <h2>25 Yesrs of Experience</h2>
                            <div class="bar"></div>
                        </div>

                        <div class="single-experience-box">
                            <div class="icon">
                                <i class="flaticon-plan"></i>
                            </div>
                            <h3>Consistency</h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur.</p>
                        </div>

                        <div class="single-experience-box">
                            <div class="icon">
                                <i class="flaticon-think"></i>
                            </div>
                            <h3>Improvement</h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur.</p>
                        </div>

                        <div class="single-experience-box">
                            <div class="icon">
                                <i class="flaticon-project"></i>
                            </div>
                            <h3>Strategy & Planning</h3>
                            <p>Risus commodo maecenas accumsan lacus vel facilisis. Lorem ipsum dolor consectetur.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="experience-image">
                    <img src="assets/img/experience-image.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Experience Area -->

<!-- Start Testimonials Area -->
<section class="testimonials-section uk-testimonials uk-section">
    <div class="uk-container">
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-box">
                <div class="user-image">
                    <img src="assets/img/user1.jpg" class="user" alt="image">
                    <img src="assets/img/circle.png" alt="shape" class="shape">
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="user-info">
                    <h3>John Smith</h3>
                    <span>Python Developer</span>
                </div>
            </div>

            <div class="single-testimonials-box">
                <div class="user-image">
                    <img src="assets/img/user2.jpg" class="user" alt="image">
                    <img src="assets/img/circle.png" alt="shape" class="shape">
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="user-info">
                    <h3>Sarah Taylor</h3>
                    <span>Python Developer</span>
                </div>
            </div>

            <div class="single-testimonials-box">
                <div class="user-image">
                    <img src="assets/img/user3.jpg" class="user" alt="image">
                    <img src="assets/img/circle.png" alt="shape" class="shape">
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="user-info">
                    <h3>Jimmy Anderson</h3>
                    <span>Python Developer</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->

<!-- Start Pricing Area -->
<section class="pricing-area uk-pricing uk-section bg-f9f9f9">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>PRICING</span>
            <h2>Our Flexible Pricing Plan</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-shout"></i>
                            <img src="assets/img/price-shape1.png" alt="image">
                        </div>
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>10GB Bandwidth Internet</li>
                            <li>Business & Financ Analysing</li>
                            <li>25 Social Media Reviews</li>
                            <li>Customer Managemet</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>

                    <div class="price">
                        $49.99
                        <span>Per Month</span>
                    </div>

                    <a href="#" class="uk-button uk-button-default">Book Now</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-plan"></i>
                            <img src="assets/img/price-shape2.png" alt="image">
                        </div>
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>15GB Bandwidth Internet</li>
                            <li>Business & Financ Analysing</li>
                            <li>30 Social Media Reviews</li>
                            <li>Customer Managemet</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>

                    <div class="price">
                        $69.99
                        <span>Per Month</span>
                    </div>

                    <a href="#" class="uk-button uk-button-default">Book Now</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                            <img src="assets/img/price-shape3.png" alt="image">
                        </div>
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>50GB Bandwidth Internet</li>
                            <li>Business & Financ Analysing</li>
                            <li>35 Social Media Reviews</li>
                            <li>Customer Managemet</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>

                    <div class="price">
                        $99.99
                        <span>Per Month</span>
                    </div>

                    <a href="#" class="uk-button uk-button-default">Book Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Project Area -->
<section class="project-area uk-project uk-section bg-f5e7da">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Projects</span>
            <h2>Let’s Check Our Latest Projects</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="single-project-item">
                    <a href="single-project.html" class="image">
                        <img src="assets/img/project4.jpg" alt="image">
                    </a>

                    <div class="content">
                        <a href="#" class="category">Design</a>
                        <h3><a href="#">UX/UI Design</a></h3>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-project-item">
                    <a href="single-project.html" class="image">
                        <img src="assets/img/project5.jpg" alt="image">
                    </a>

                    <div class="content">
                        <a href="#" class="category">Development</a>
                        <h3><a href="#">Web Development</a></h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="single-project-item">
                    <a href="single-project.html" class="image">
                        <img src="assets/img/project6.jpg" alt="image">
                    </a>

                    <div class="content">
                        <a href="#" class="category">Arts</a>
                        <h3><a href="#">Book Cover</a></h3>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-project-item">
                    <a href="single-project.html" class="image">
                        <img src="assets/img/project7.jpg" alt="image">
                    </a>

                    <div class="content">
                        <a href="#" class="category">Development</a>
                        <h3><a href="#">Illustrations</a></h3>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-project-item">
                    <a href="single-project.html" class="image">
                        <img src="assets/img/project8.jpg" alt="image">
                    </a>

                    <div class="content">
                        <a href="#" class="category">Design</a>
                        <h3><a href="#">Company Branding</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Area -->

<div class="uk-border">
    <div class="uk-container">
        <div class="uk-border"></div>
    </div>
</div>

<!-- Start Partner Area -->
<section class="partner-area-two uk-partner uk-section bg-f5e7da">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
            <div class="item">
                <div class="uk-section-title section-title">
                    <span>Our Clients</span>
                    <h2>Clients We Work</h2>
                    <div class="bar"></div>
                </div>
            </div>

            <div class="item">
                <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner1.png" alt="image">
                        </div>
                    </div>

                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner2.png" alt="image">
                        </div>
                    </div>

                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner3.png" alt="image">
                        </div>
                    </div>

                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner4.png" alt="image">
                        </div>
                    </div>

                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner5.png" alt="image">
                        </div>
                    </div>

                    <div class="item">
                        <div class="partner-item">
                            <img src="assets/img/partner6.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Partner Area -->

<!-- Start Blog Area -->
<section class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>News</span>
            <h2>Latest News Update</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="single-blog-post-item">
                    <a href="single-blog.html" class="post-image">
                        <img src="assets/img/blog4.jpg" alt="image">
                    </a>

                    <div class="post-content">
                        <a href="#" class="category">UX/UI</a>
                        <h3><a href="single-blog.html">University Admissions Could Face Emergency Controls</a></h3>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-blog-post-item">
                    <a href="single-blog.html" class="post-image">
                        <img src="assets/img/blog5.jpg" alt="image">
                    </a>

                    <div class="post-content">
                        <a href="#" class="category">DEVELOPMENT</a>
                        <h3><a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a></h3>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-blog-post-item">
                    <a href="single-blog.html" class="post-image">
                        <img src="assets/img/blog6.jpg" alt="image">
                    </a>

                    <div class="post-content">
                        <a href="#" class="category">DESIGN</a>
                        <h3><a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Newsletter Area -->
<section class="uk-newsletter newsletter-area">
    <div class="uk-container">
        <div class="newsletter-content">
            <span class="sub-title">Newsletter</span>
            <h2>Subscribe To Our Newsletter</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <form class="newsletter-form">
                <input type="email" class="uk-input" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit" class="uk-button uk-button-default">Subscribe Now</button>
            </form>
        </div>
    </div>
</section>
<!-- End Newsletter Area -->